import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Link } from "react-router";
import { Helmet } from "react-helmet";
import MailingListPopup from "../../components/general/MailingListPopup";
import FaFacebookSquare from "../../fa/facebook-square";
import FaInstagram from "../../fa/instagram";
import FaTwitter from "../../fa/twitter";
import FaPinterestSquare from "../../fa/pinterest-square";
import video from '../../Teuko kids lunchbox ideas community landing features Feed _compressed.mp4'
import video2 from '../../Teuko kids lunchbox ideas community landing features Search _compressed.mp4'

class HomePage extends Component {
  UNSAFE_componentWillMount() {
    document.body.classList.add("home");
  }

  componentWillUnmount() {
    document.body.classList.remove("home");
  }

  registerClick(e) {
    e.preventDefault();
    document.getElementById("sign-up").click();
  }

  render() {
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (

      <div className="home-page">
        <Helmet>
          <title>Teuko - Easy and Fun Bento Lunchbox Ideas for Kids</title>
          <script type='application/ld+json' dangerouslySetInnerHTML={ { __html: `
            {
              "@context": "https://schema.org/",
              "@type": "ImageObject",
              "contentUrl": "https://www.teuko.com/images/mockup_20230119_Teuko_kids_lunchbox_ideas_school_lunch_food_community_bento.webp",
              "license": "https://www.teuko.com",
              "acquireLicensePage": "https://example.com/how-to-use-my-images",
              "creditText": "Teuko Kids Lunchbox Ideas",
              "creator": {
                "@type": "Person",
                "name": "Teuko"
              },
              "copyrightNotice": "Teuko"
            }`}} />
            
        </Helmet>


        <MailingListPopup delayInMilliseconds={12000} client={this.props.client}/>

        <div className="landing-heading-section">

          <div className="landing-heading-section-containerflex">
          
            <div className="landing-heading-section-textcta">
              <div className="landing-heading-section-subtitle">
              {/* <h1>Lunch Packing for Kids<br/>Made Easy and Fun</h1> */}
              {/* <h1>Easy and Fun<br/>Lunchbox Ideas for Kids</h1> */}
              <h1 className="landing-heading-title-h1">What will you pack for <span className="landing-highlight-word-in-title">lunch</span> today?</h1>
                <p>Get lunchbox ideas, recipes, and tips powered by families around the world.<br/>
                  Enjoy challenges and giveaways.
                </p>
              </div>

              <div className="container-browse-all-btn">
                <Link to="/signup" className="standard-button browse-all-button">
                  Try now
                </Link>
              </div>
              <p className="landing-text-xs">
                Sign Up Free. No credit card required.
              </p>
            </div>

            <div className="landing-heading-section-image">
              <a id="image_landing"
                 href="/signup">
                <img
                  src="/images/mockup_20230119_Teuko_kids_lunchbox_ideas_school_lunch_food_community_bento.webp"
                  className="landing-heading-lunch-image"
                  alt="Teuko kids lunchbox ideas community school lunch bento food accessories themes on any devices"
                />
              </a>
            </div>

          </div>

          <div className="a0G">
            <div className="BcmU7">
              <div className="homepage-supporteradbox">
                <div className="homepage-supporteradtext">
                  <div className="homepage-supporteradimage">
                    <a
                      id="link_ad_homepage"
                      href="https://www.lelycee.org?utm_source=teuko"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/images/Logo_Lycee_Francais_de_San_Francisco_LFSF_SchoolTeuko_400x157.png"
                        className="homepage-supporteradimagespec"
                        alt="Lycée Français de San Francisco Supporter Teuko Lunchbox Community"
                      />
                    </a>
                  </div>
                  <div className="homepage-supporteradtextspec">
                    Proud supporter of Teuko
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="contest-announcement-homepage">
          <h3 className="contest-title">
            <a
                className="contest-title-link"
                target="blank"
                href="https://mailchi.mp/b03035576d83/free-starter-kit"
                rel="noopener noreferrer"
              >
                FREE PDF PRINTABLE - Tips from the Teuko Family to start an amazing lunch packing experience! <span className="full-menu-option-cta-button-message">Get it now</span>
              </a> 
          </h3>
        </div>

        {/* <div className="contest-announcement-homepage">
          <h3 className="contest-title">
            <a
                className="contest-title-link"
                href="/challenges"
              >
                GIVEAWAY! One COSORI Air Fryer to win. <span className="full-menu-option-cta-button-message">Enter Now</span>
              </a> 
          </h3>
        </div> */}
              
              {/* FREE PRINTABLE - All the basics you need to know before you get
                started with lunch packing. Get it now! */}

            
         

        {/* Old mailchimp link for free printable - not summer https://mailchi.mp/b03035576d83/free-starter-kit */}
        {/* Mailchimp link for free summer camp printable  https://mailchi.mp/2dc8d3831750/summercamp-lunch-101 */}


        {/* <div className="contest-announcement-homepage">
          <h3 className="contest-title">
                <a
                  className="contest-title-link"
                  target="blank"
                  href="/giveaway"
                  rel="noopener noreferrer"
                >
                  GIVEAWAY! Over $1000 in fun prizes to win. Enter your chance today! >
                </a>
          </h3>
        </div> */}

        {/* <div className="contest-announcement-homepage-with-image">
          <a href="https://www.facebook.com/teukoapp/posts/pfbid02QHtvtWeVGV4rK4RbjP5uVbF5Af7Kk3cGp7hEkDCYu6fH5VErvbdE9skBDzWwGdBwl" target="_blank"
              rel="noopener noreferrer">
            <div
              key="giveawaybannersmartphone"
              className="contest-announcement-homepage-image"
              alt="Giveaway Banner"
              style={{
                backgroundImage: "url(/images/homepagesmartphonevalentinesdaygiveaway2024_violet.png)",
              }}
            ></div>
          </a>
          <h3 className="contest-title">
                <a
                  className="contest-title-link"
                  href="https://www.facebook.com/teukoapp/posts/pfbid02QHtvtWeVGV4rK4RbjP5uVbF5Af7Kk3cGp7hEkDCYu6fH5VErvbdE9skBDzWwGdBwl" target="_blank"
              rel="noopener noreferrer"
                >
                  GIVEAWAY! Win a $100 Lunch Prep Bundle!<span className="full-menu-option-cta-button-message">Enter Now</span>
                </a>
          </h3>
        </div> */}

         

          {/* <a href="/giveaway">
            <div key="giveawaybannersmartphone_landingpage" className="giveaway-splash-header-smartphone" alt="Giveaway Banner" style={{ backgroundImage: 'url(/images/giveawaybannersmartphone.jpg)' }}></div>  
            <div key="giveawaybannerlaptop_landingpage" className="giveaway-splash-header-laptop" alt="Giveaway Banner" style={{ backgroundImage: 'url(/images/giveawaybannerlaptop.jpg)' }}></div>
          </a>  */}
        
        {/* <div className="container-browse-btn_giveaway-landing">
              <Link to="/giveaway" className="standard-button browse-all-button">
                Learn More
              </Link>
        </div> */}

        <div className="landing-features-section">

        <div className="landing-features-section-containerflex" id="feature-title">
          <h1>With Teuko, get lunchbox superpowers!</h1>
        </div>

          <div className="landing-features-section-containerflex" id="feature-feed">
              <div>
                <video class="video_demo_teuko_feed_on_landing_page"  title="Feed Teuko Kids Lunchbox Ideas Community" loop={true} autoPlay={true} playsinline={true} muted={true} >
                  <source src={video} type="video/mp4" />
                </video>
              </div>
                {/* 
                <div className="landing-features-section-image">
              <a key="link_to_feed_from_image_description1" href="/feed">
                <img
                  src="/images/mockup_20210707_feature1_teukofeed.jpg"
                  className="landing-features-lunch-image"
                  alt="Teuko lunchbox community feature feed kids bento lunchbox ideas"
                /> 
                 </a>
            </div>
            */}
             
            <div className="landing-features-section-textcta">
              <div className="landing-features-section-subtitle">
              <h2>Simple, quick, and easy kids' lunchbox ideas</h2>
                <p>Discover new bento lunch box ideas for kids every day in the <a key="link_to_feed_from_description1" href="/feed" className="link_to_feed_from_description">Teuko Feed</a>.<br/>Shared by our worldwide community of parents who pack lunch.<br/>Curated by our team, or personalized just for you!
                </p>
              </div>
            </div>
          </div>

          <div className="landing-features-section-containerflex" id="feature-search">
            {/* <div className="landing-features-section-image">
              <a key="link_to_feed_from_image_description2" href="/search">
                <img
                  src="/images/mockup_20210707_feature2_teukosearch.jpg"
                  className="landing-features-lunch-image"
                  alt="Teuko lunchbox community feature search foods themes kids bento lunchbox ideas"
                />
              </a>
            </div> */}
            <div>
                <video class="video_demo_teuko_feed_on_landing_page"  title="Search Teuko Kids Lunchbox Ideas Community" loop={true} autoPlay={true} playsinline={true} muted={true}>
                  <source src={video2} type="video/mp4" />
                </video>
              </div>
            <div className="landing-features-section-textcta">
              <div className="landing-features-section-subtitle">
              <h2>The lunchbox idea you want, your way!</h2>
                <p>With the <a key="link_to_feed_from_description2" href="/search" className="link_to_feed_from_description">Teuko Search</a> tool, filter thousands of lunchbox ideas. Get inspiration with the foods you like, with trends or themes your kid likes, with inspiring friends, and more. Then, pack your kid's lunch with your own style!
                </p>
              </div>
            </div>
          </div>

          <div className="landing-features-section-containerflex" id="feature-funtips">
            <div className="landing-features-section-image">
              <a key="link_to_feed_from_image_description3" href="/challenges">
                <img
                  src="/images/mockup_20230125_feature5_teukofuntips.webp"
                  className="landing-features-lunch-image2"
                  alt="Teuko lunchbox community feature fun tips challenges giveaways kids bento lunchbox ideas"
                />
              </a>
            </div>
            <div className="landing-features-section-textcta">
              <div className="landing-features-section-subtitle">
              <h2>Your are not alone anymore. Lunch packing fun is coming to you!</h2>
                <p>Be inspired by easy kid-approved lunch recipes, foodie trends, and tips shared by our community on the <a key="link_to_feed_from_description2" href="/blog" className="link_to_feed_from_description">Teuko Blog</a>. Join our cool <a key="link_to_feed_from_description3" href="/challenges" className="link_to_feed_from_description">challenges and giveaways</a>. With ongoing motivation, you are not alone anymore against the lunchbox blues.</p>
              </div>
            </div>
          </div>



        </div>

        <div className="simple-fun-section-landing">
          {/* <h2>Enjoy all the resources in one place</h2> */}

          {/* <div className="my-categories-explore-search-tip-boutique-laptop">
            <div className="my-categories-header">
              <div className="category-ideas-landing">
                <div className="landing-explore-grid">
                  <div
                    className="infocard-landing"
                    data-qa="explore_teuko_lunch_ideas"
                  >
                    <div className="infocard-landing-content">
                      <a href="/feed">
                        <img
                          src="/images/landing_explore_lunch.jpg"
                          className="infocard-landing-image"
                          alt="Teuko Lunch Box Ideas"
                        />
                      </a>
                    </div>
                    <div className="infocard-header-landing">
                      <a href="/feed">
                        <h3>Browse Categories ></h3>
                      </a>
                    </div>
                  </div>

                  <div
                    className="infocard-landing"
                    data-qa="explore_teuko_lunch_ideas"
                  >
                    <div className="infocard-landing-content">
                      <a href="/search">
                        <img
                          src="/images/landing_explore_searchfoods.jpg"
                          className="infocard-landing-image"
                          alt="Teuko Lunch Box Ideas"
                        />
                      </a>
                    </div>
                    <div className="infocard-header-landing">
                      <a href="/search">
                        <h3>Search by Foods ></h3>
                      </a>
                    </div>
                  </div>

                  <div
                    className="infocard-landing"
                    data-qa="explore_teuko_lunch_ideas"
                  >
                    <div className="infocard-landing-content">
                      <a href="/blog">
                        <img
                          src="/images/landing_explore_tips.jpg"
                          className="infocard-landing-image"
                          alt="Teuko Lunch Box Ideas"
                        />
                      </a>
                    </div>
                    <div className="infocard-header-landing">
                      <a href="/blog">
                        <h3>Tips & Recipes ></h3>
                      </a>
                    </div>
                  </div>

                  <div
                    className="infocard-landing"
                    data-qa="explore_teuko_lunch_ideas"
                  >
                    <div className="infocard-landing-content">
                      <a href="/boutique">
                        <img
                          src="/images/landing_explore_accessories.jpg"
                          className="infocard-landing-image"
                          alt="Teuko Lunch Box Ideas"
                        />
                      </a>
                    </div>
                    <div className="infocard-header-landing">
                      <a href="/boutique">
                        <h3>Accessories ></h3>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          


          {/* <div className="standard-container">
            <div className="lunch-and-bullets">
              <img
                src="/images/mockups_short.png"
                className="lunch-image"
                alt="Teuko lunchbox community devices"
              />

              <div className="features-list">
                <div className="feature-item">
                  <img src="/icons/idea.png" alt="idea" />
                  <div className="feature-text">
                    <p className="feature-title">Discover</p>
                    <p>
                      Filter thousands of lunchbox ideas. Inspiration by foods,
                      trends, friends, and more.
                    </p>
                  </div>
                </div>
                <div className="feature-item">
                  <img src="/icons/clipboard.png" alt="idea" />
                  <div className="feature-text">
                    <p className="feature-title">Track</p>
                    <p>
                      Create your lunchbox diary. Get a meal plan at the touch
                      of a button.
                    </p>
                  </div>
                </div>
                <div className="feature-item">
                  <img src="/icons/meeting.png" alt="idea" />
                  <div className="feature-text">
                    <p className="feature-title">Share</p>
                    <p>
                      Be inspired and inspire. With ongoing motivation, you are
                      not alone anymore against the lunchbox blues.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="container-browse-all-btn">
            <Link to="/signup" className="standard-button browse-all-button">
              Join Now! Free!
            </Link>
          </div>
        </div>

        {/*<div className="latest-lunches-plus-reviews">
          <div className="standard-container">
            <div className="latest-lunches">
              <h2>Explore Teuko</h2>

            </div>
          </div>
        </div> */}

        

        {/* <div className="separator-home" />

        <div className="subscribe">
          <div className="standard-container">
            <h2>Get Free Lunchbox Ideas and Tips in Your Inbox!</h2>
            <div className="container-browse-all-btn">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://eepurl.com/diTFKH"
                className="standard-button browse-all-button"
              >
                Subscribe
              </a>
            </div>
          </div>
        </div> */}

        <div className="contest-announcement-homepage">
            <h3 className="contest-title">
              <a className="contest-title-link">
                  #TEUKO | Spread the word and share Teuko with your friends and family!
              </a>
            </h3>
            <ul className="landing-social-shares">
              
              <li className="landing-social-shares-list-item">
                <a data-share-url="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.teuko.com"
                   data-ga-action="Facebook share"
                   data-ga-event="Site"
                   aria-describedby="NewWindow"
                   className="social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
                   href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.teuko.com"
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  <FaFacebookSquare />
                  <span className="visuallyhidden">Facebook</span>
                  <span className="kids-social-share__button-text">Share</span>
                </a>
              </li>

              <li className="landing-social-shares-list-item">
                <a data-share-url="https://www.instagram.com/teukoapp"
                   data-ga-action="Instagram follow"
                   data-ga-event="Site"
                   aria-describedby="NewWindow"
                   className="social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
                   href="https://www.instagram.com/teukoapp"
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  <FaInstagram />
                  <span className="visuallyhidden">Instagram</span>
                  <span className="kids-social-share__button-text">Follow</span>
                </a>
              </li>

              <li className="landing-social-shares-list-item">
                <a data-share-url="https://twitter.com/intent/tweet?text=Unlock+lunch+packing+superpowers+with+Teuko%21+Get+kids+lunchbox+ideas%2C+tips%2C+and+fun+shared+by+families+around+the+world%E2%80%94+https%3A%2F%2Fwww.teuko.com"
                   data-ga-action="Twitter share"
                   data-ga-event="Site"
                   aria-describedby="NewWindow"
                   className="social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
                   href="https://twitter.com/intent/tweet?text=Unlock+lunch+packing+superpowers+with+Teuko%21+Get+kids+lunchbox+ideas%2C+tips%2C+and+fun+shared+by+families+around+the+world%E2%80%94+https%3A%2F%2Fwww.teuko.com"
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  <FaTwitter />
                  <span className="visuallyhidden">Twitter</span>
                  <span className="kids-social-share__button-text">Tweet</span>
                </a>
              </li>

              {/* <li className="landing-social-shares-list-item">
                <a data-share-url="https://www.pinterest.com/teukoapp"
                   data-ga-action="Pinterest Pinit"
                   data-ga-event="Site"
                   aria-describedby="NewWindow"
                   className="social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
                   href="https://www.pinterest.com/teukoapp"
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  <FaPinterestSquare />
                  <span className="visuallyhidden">Pinterest</span>
                  <span className="kids-social-share__button-text">Pin it</span>
                </a>
              </li> */}


            </ul>
          

            {/* <h3 className="contest-title">
              <a
                className="contest-title-link"
                target="blank"
                href="https://mailchi.mp/b03035576d83/free-starter-kit"
                rel="noopener noreferrer"
              >
                FREE PRINTABLE - All the basics you need to know before you get
                started with lunch packing. Get it now! >
              </a>
            </h3> */}
            {/* <p className="contest-text">
              <a className="contest-rules" target="blank" href="https://teuko.us12.list-manage.com/subscribe?u=15abbcd598b56f261ab4540b0&id=125229f0fc" rel="noopener noreferrer"> Learn more></a>
            </p> */}
          </div> 

        <div className="featured-in">
          <div className="standard-container">
            <h2>As featured in</h2>
          </div>
          <div className="standard-container feature-options">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://tinybeans.com/cooking-baking-kitchen-resources-offers-for-kids/slide/2?utm_source=teuko"
            >
              <img alt="" src="/images/tinybeanslogo.jpg" />
            </a>
            
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.cnn.com/travel/article/cnn-food-challenge-lunch/index.html?utm_source=teuko"
            >
              <img alt="" src="/images/cnnlogo.jpg" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://techcrunch.com/video/teuko-lunchbox-collaboration/?utm_source=teuko"
            >
              <img alt="" src="/images/tclogo_short.jpg" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.welum.com/article/lunch-packing-creativity-enhanced-together/?utm_source=teuko"
            >
              <img alt="" src="/images/welumlogo.gif" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://gosolo.subkit.com/teuko/?utm_source=teuko"
            >
              <img alt="" src="/images/gosolologo.jpg" />
            </a>
            {/* <a target="_blank" rel="noopener noreferrer" href="https://blog.icliniq.com/icliniq-partners-with-teuko-to-inspire-and-foster-healthy-eating-habits/?utm_source=teuko"><img alt="" src="/images/icliniqlogo_landing.jpg" /></a> */}
          </div>
        </div>

      </div> 
      
      

    );
  }
}

export default withApollo(HomePage);
